import { useRoutes } from 'react-router-dom';
import { PublicRoute, PrivateRoute, PrivateAdminRoute } from 'components/handler';

// home page
import HomePage from 'pages/home/HomePage';

// account page
import RegisterPage from 'pages/account/RegisterPage';
import LoginPage from 'pages/account/LoginPage';
import ForgotPasswordPage from 'pages/account/ForgotPasswordPage';
import ResetPasswordPage from 'pages/account/ResetPasswordPage';
import ProfilePage from 'pages/account/ProfilePage';
import AdminLoginPage from 'pages/admin/account/AdminLoginPage';

// job page
import JobListPage from 'pages/job/JobListPage';
import JobDetailPage from 'pages/job/JobDetailPage';
import JobApplyPage from 'pages/job/JobApplyPage';
import AdminJobsPage from 'pages/admin/job/AdminJobsPage';
import AdminJobDetailPage from 'pages/admin/job/AdminJobDetailPage';
import AddJobPage from 'pages/admin/job/AddJobPage';
import EditJobPage from 'pages/admin/job/EditJobPage';
import AdminJobArchivePage from 'pages/admin/job/AdminJobArchivePage';

// application page
import ApplicationListPage from 'pages/application/ApplicationListPage';
import ApplicationDetailPage from 'pages/application/ApplicationDetailPage';

// candidate page
import CandidatesPage from 'pages/admin/candidate/CandidatesPage';
import CandidateDetailPage from 'pages/admin/candidate/CandidateDetailPage';
import AdminCandidatesArchivePage from 'pages/admin/candidate/AdminCandidatesArchivePage';

// user admin
import AdminProfilePage from 'pages/admin/account/AdminProfilePage';
import UsersAdminPage from 'pages/admin/user/UsersAdminPage';
import UsersCandidatePage from 'pages/admin/user/UsersCandidatePage';

// admin setting
import SchoolSettingPage from 'pages/admin/setting/SchoolSettingPage';

// admin master
import JobPositionPage from 'pages/admin/master/JobPositionPage';
import JobStatusPage from 'pages/admin/master/JobStatusPage';
import SchoolLocationPage from 'pages/admin/master/SchoolLocationPage';
import SchoolPage from 'pages/admin/master/SchoolPage';
import SkillPage from 'pages/admin/master/SkillPage';

// default
import Page404 from 'pages/Page404';

/**
 * NOTES
 * 1. gunakan wrapper <PublicRoute /> hanya untuk route yang membutuhkan untuk strict redirect ke private route
 */
export const PUBLIC_ROUTES = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/forgotpassword',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/resetpassword',
    element: <ResetPasswordPage />,
  },
  {
    path: '/profile',
    element: (
      <PrivateRoute>
        <ProfilePage />
      </PrivateRoute>
    ),
  },
  {
    path: '/jobs',
    element: (
      <PrivateRoute>
        <JobListPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/jobs/:jobId',
    element: <JobDetailPage />,
  },
  {
    path: '/jobs/:jobId/profile',
    element: (
      <PrivateRoute>
        <JobApplyPage />
      </PrivateRoute>
    ),
  },

  {
    path: '/applications',
    element: (
      <PrivateRoute>
        <ApplicationListPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/applications/:applicationId',
    element: (
      <PrivateRoute>
        <ApplicationDetailPage />
      </PrivateRoute>
    ),
  },

  /** Admin Route */
  {
    path: '/admin',
    element: <AdminLoginPage />,
  },
  {
    path: '/admin/profile',
    element: <AdminProfilePage />,
  },
  {
    path: '/admin/jobs',
    element: (
      <PrivateAdminRoute>
        <AdminJobsPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/jobs/detail',
    element: (
      <PrivateAdminRoute>
        <AdminJobDetailPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/jobs/add',
    element: (
      <PrivateAdminRoute>
        <AddJobPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/jobs/:jobId/edit',
    element: (
      <PrivateAdminRoute>
        <EditJobPage />
      </PrivateAdminRoute>
    ),
  },

  {
    path: '/admin/candidates',
    element: (
      <PrivateAdminRoute>
        <CandidatesPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/candidates/:applicationId',
    element: (
      <PrivateAdminRoute>
        <CandidateDetailPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/setting/school',
    element: (
      <PrivateAdminRoute>
        <SchoolSettingPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/master/jobposition',
    element: (
      <PrivateAdminRoute>
        <JobPositionPage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/master/jobstatus',
    element: (
      <PrivateAdminRoute>
        <JobStatusPage />
      </PrivateAdminRoute>
    ),
  },
  // {
  //   path: '/admin/master/school',
  //   element: (
  //     <PrivateAdminRoute>
  //       <SchoolPage />
  //     </PrivateAdminRoute>
  //   ),
  // },
  // {
  //   path: '/admin/master/location',
  //   element: (
  //     <PrivateAdminRoute>
  //       <SchoolLocationPage />
  //     </PrivateAdminRoute>
  //   ),
  // },
  {
    path: '/admin/archive/jobs',
    element: (
      <PrivateAdminRoute>
        <AdminJobArchivePage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/archive/candidates',
    element: (
      <PrivateAdminRoute>
        <AdminCandidatesArchivePage />
      </PrivateAdminRoute>
    ),
  },
  {
    path: '/admin/master/skill',
    element: (
      <PrivateAdminRoute>
        <SkillPage />
      </PrivateAdminRoute>
    ),
  },
  // {
  //   path: '/admin/users/admin',
  //   element: (
  //     <PrivateAdminRoute>
  //       <UsersAdminPage />
  //     </PrivateAdminRoute>
  //   ),
  // },
  {
    path: '/admin/users/candidate',
    element: (
      <PrivateAdminRoute>
        <UsersCandidatePage />
      </PrivateAdminRoute>
    ),
  },
];

export const PRIVATE_ROUTES = [];

const OTHER_ROUTES = [{ path: '*', element: <Page404 /> }];

// export const routes = Object.values({ ...PRIVATE_ROUTES, ...PUBLIC_ROUTES });
export const routes = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES, ...OTHER_ROUTES];

// initialize route
const RouteElement = (props) => {
  const elements = useRoutes(routes);
  return elements;
};

export default RouteElement;
