import { lazy, Suspense } from 'react';
import { Layout } from 'components/themes/v1';

const Register = lazy(() => import('modules/account/Register'));

const RegisterPage = (props) => (
  <Layout>
    <Suspense fallback={null}>
      <Register />
    </Suspense>
  </Layout>
);

export default RegisterPage;
