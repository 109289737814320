import styled from '@emotion/styled';
import { Header, PrivateHeader } from '.';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #edecec;
  overflow-y: auto;
`;

/** Default layout */
/**
 * in real app
 * please replace isLoggedIn from props to redux
 */
const AdminPublicLayout = ({ children, isLoggedIn }) => <Wrapper>{children}</Wrapper>;

export default AdminPublicLayout;
