import _get from 'lodash/get';

export const getUrlSegment = (segment) => {
  const path = window.location.pathname;
  const splitted = path.split('/');

  const result = _get(splitted, `${segment}`) || '';
  return result;
};

export const downloadFromURL = (url) => {
  if (!url) {
    return;
  }
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', `_blank`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
