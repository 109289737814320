import { lazy, Suspense } from 'react';
import { Layout } from 'components/themes/v1';

const ApplicationDetail = lazy(() => import('modules/application/ApplicationDetail'));

const ApplicationDetailPage = (props) => (
  <Layout>
    <Suspense fallback={null}>
      <ApplicationDetail />
    </Suspense>
  </Layout>
);

export default ApplicationDetailPage;
