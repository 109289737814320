const tokenName = 'ustn';
const registrationName = 'rgst';
const userKey = 'usr';
const adminTokenName = 'atkn';
const adminKey = 'adt';
const langKey = 'lng';
const lastRequestTime = 'lreq_time';

export const setLang = (lang) => {
  window.localStorage.setItem(langKey, lang);
};

export const getLang = () => {
  const lang = window.localStorage.getItem(langKey);
  return lang;
};

/** Admin Token */
export const setAdminTokenStorage = (token) => {
  window.localStorage.setItem(adminTokenName, token);
};

export const getAdminTokenStorage = () => {
  const token = window.localStorage.getItem(adminTokenName);
  return token;
};

export const clearAdminTokenStorage = () => {
  window.localStorage.removeItem(adminTokenName);
};

/** Admin Data */
export const setAdminDataStorage = (data) => {
  window.localStorage.setItem(adminKey, JSON.stringify(data));
};

export const getAdminDataStorage = () => {
  const data = window.localStorage.getItem(adminKey);
  return JSON.parse(data);
};

export const clearAdminDataStorage = () => {
  window.localStorage.removeItem(adminKey);
};

/** User Token */
export const setUserTokenStorage = (token) => {
  window.localStorage.setItem(tokenName, token);
};

export const getUserTokenStorage = () => {
  const token = window.localStorage.getItem(tokenName);
  return token;
};

export const clearUserTokenStorage = () => {
  window.localStorage.removeItem(tokenName);
};

/** User Data */
export const setUserDataStorage = (data) => {
  window.localStorage.setItem(userKey, JSON.stringify(data));
};

export const getUserDataStorage = () => {
  const data = window.localStorage.getItem(userKey);
  return JSON.parse(data);
};

export const clearUserDataStorage = () => {
  window.localStorage.removeItem(userKey);
};

/** Last Request Time */
export const setLastRequestTime = (data) => {
  window.localStorage.setItem(lastRequestTime, data);
};

export const getLastRequestTime = () => {
  return window.localStorage.getItem(lastRequestTime);
};

export const clearLastRequestTime = () => {
  window.localStorage.removeItem(lastRequestTime);
};
