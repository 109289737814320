import api from 'libs/api';
import { school as schoolUrl } from './endpoint';

export const getSchoolData = async (config = {}) => {
  const resp = await api().get(schoolUrl.index, config);
  return resp;
};

export const updateSchoolData = async (data, config = {}) => {
  const resp = await api().post(schoolUrl.index, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...config,
    },
  });

  return resp;
};
