import { lazy, Suspense } from 'react';
import { Layout } from 'components/themes/v1';

const ApplicationList = lazy(() => import('modules/application/ApplicationList'));

const ApplicationListPage = (props) => (
  <Layout>
    <Suspense fallback={null}>
      <ApplicationList />
    </Suspense>
  </Layout>
);

export default ApplicationListPage;
