import _template from 'lodash/template';
import api from 'libs/api';
import { notification as notifUrl } from './endpoint';

export const getAllNotif = async (params, config = {}) => {
  const resp = await api().get(notifUrl.notification, { params, ...config });
  return resp;
};

export const getLatestNotif = async (params, config = {}) => {
  const resp = await api().get(notifUrl.latest, { params, ...config });
  return resp;
};

export const readNotification = async (data, config = {}) => {
  const resp = await api().put(notifUrl.read, data, config);
  return resp;
};
