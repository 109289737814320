import { lazy, Suspense } from 'react';
import { AdminPublicLayout } from 'components/themes/v1';

const AdminLogin = lazy(() => import('modules/admin/account/AdminLogin'));

const AdminLoginPage = (props) => (
  <AdminPublicLayout>
    <Suspense fallback={null}>
      <AdminLogin />
    </Suspense>
  </AdminPublicLayout>
);

export default AdminLoginPage;
