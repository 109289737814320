import _get from 'lodash/get';
import { useLocation, Navigate } from 'react-router-dom';
import { getAdminTokenStorage, getAdminDataStorage } from 'utils/localStorage';
import { PERSONALIA_ROLES } from 'constants/role';

/**
 * allowed is rule to limit user access
 * if allowed not set, all admin role can access the page
 * eg: <PrivateAdminRoute allowed={[ROLE_SUPERADMIN, ROLE_STAFF_PERSONALIA]}></PrivateAdminRoute>
 */
const PrivateAdminRoute = ({ children, allowed }) => {
  const location = useLocation();
  const token = getAdminTokenStorage();
  const adminData = getAdminDataStorage();
  const roleId = _get(adminData, 'role_id') || '';

  const allowedRole = Array.isArray(allowed) && allowed.length > 0 ? allowed : PERSONALIA_ROLES;

  if (!token || !adminData || !allowedRole.includes(roleId)) {
    return <Navigate to='/admin' state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateAdminRoute;
