import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const SchoolSetting = lazy(() => import('modules/admin/setting/SchoolSetting'));

const SchoolSettingPage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <SchoolSetting />
    </Suspense>
  </AdminLayout>
);

export default SchoolSettingPage;
