import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import {
  NOTIF_SET_ADMIN_LATEST,
  NOTIF_SET_ADMIN_DATA,
  NOTIF_SET_ADMIN_UNREAD,
  NOTIF_SET_USER_LATEST,
  NOTIF_SET_USER_DATA,
  NOTIF_SET_USER_UNREAD,
} from 'store/types';

export default createReducer(initialState, {
  [NOTIF_SET_ADMIN_LATEST]: (state, action) => setAdminLatest(state, action.payload),
  [NOTIF_SET_ADMIN_DATA]: (state, action) => setAdminData(state, action.payload),
  [NOTIF_SET_ADMIN_UNREAD]: (state, action) => setAdminUnread(state, action.payload),
  [NOTIF_SET_USER_LATEST]: (state, action) => setUserLatest(state, action.payload),
  [NOTIF_SET_USER_DATA]: (state, action) => setUserData(state, action.payload),
  [NOTIF_SET_USER_UNREAD]: (state, action) => setUserUnread(state, action.payload),
});

const setAdminLatest = (state, payload) => {
  return update(state, {
    adminNotif: {
      latest: { $set: payload },
    },
  });
};

const setAdminData = (state, payload) => {
  const { data, pagination } = payload;

  return update(state, {
    adminNotif: {
      data: { $set: data },
      pagination: { $set: pagination },
    },
  });
};

const setAdminUnread = (state, payload) => {
  return update(state, {
    adminNotif: {
      unread: { $set: payload },
    },
  });
};

const setUserLatest = (state, payload) => {
  return update(state, {
    userNotif: {
      latest: { $set: payload },
    },
  });
};

const setUserData = (state, payload) => {
  const { data, pagination } = payload;

  return update(state, {
    userNotif: {
      data: { $set: data },
      pagination: { $set: pagination },
    },
  });
};

const setUserUnread = (state, payload) => {
  return update(state, {
    userNotif: {
      unread: { $set: payload },
    },
  });
};
