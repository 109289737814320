import { lazy, Suspense } from 'react';
import { Layout } from 'components/themes/v1';

const JobList = lazy(() => import('modules/job/JobList'));

const JobListPage = (props) => (
  <Layout>
    <Suspense fallback={null}>
      <JobList />
    </Suspense>
  </Layout>
);

export default JobListPage;
