import { useLocation, Navigate } from 'react-router-dom';

import { getUserTokenStorage, getUserDataStorage } from 'utils/localStorage';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const token = getUserTokenStorage();
  const userData = getUserDataStorage();

  if (!token || !userData) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
