import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import {
  MASTER_SET_JOB_STATUS,
  MASTER_SET_SCHOOL,
  MASTER_SET_SCHOOL_LOCATION,
  MASTER_SET_SKILL,
  MASTER_SET_JOB_POSITION,
} from 'store/types';

export default createReducer(initialState, {
  [MASTER_SET_JOB_POSITION]: (state, action) => jobPositionUpdate(state, action.payload),
  [MASTER_SET_JOB_STATUS]: (state, action) => jobStatusUpdate(state, action.payload),
  [MASTER_SET_SCHOOL]: (state, action) => schoolUpdate(state, action.payload),
  [MASTER_SET_SCHOOL_LOCATION]: (state, action) => schoolLocation(state, action.payload),
  [MASTER_SET_SKILL]: (state, action) => skillUpdate(state, action.payload),
});

const jobPositionUpdate = (state, payload) => {
  return update(state, {
    master: {
      jobPosition: { $set: payload },
    },
  });
};

const jobStatusUpdate = (state, payload) => {
  return update(state, {
    master: {
      jobStatus: { $set: payload },
    },
  });
};

const schoolUpdate = (state, payload) => {
  return update(state, {
    master: {
      school: { $set: payload },
    },
  });
};

const schoolLocation = (state, payload) => {
  return update(state, {
    master: {
      schoolLocation: { $set: payload },
    },
  });
};

const skillUpdate = (state, payload) => {
  return update(state, {
    master: {
      skill: { $set: payload },
    },
  });
};
