import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const EditJob = lazy(() => import('modules/admin/Job/EditJob'));

const EditJobPage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <EditJob />
    </Suspense>
  </AdminLayout>
);

export default EditJobPage;
