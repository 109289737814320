export default {
  lang: 'id',
  account: {
    detail: {},
    adminDetail: {},
  },
  school: {
    detail: {},
  },
  master: {
    jobStatus: [],
    school: [],
    schoolLocation: [],
    skill: [],
    jobPosition: [],
  },
  user: {
    admin: {
      data: [],
      pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 1,
      },
    },
    candidate: {
      data: [],
      pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 1,
      },
    },
  },
  job: {
    data: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 1,
    },
    detail: {},
    recommendation: [],
  },
  application: {
    data: [],
    detail: {
      user: {},
      application: {},
    },
  },
  userApplication: {
    data: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 1,
    },
  },
  adminNotif: {
    latest: [],
    unread: 0,
    data: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 1,
    },
  },
  userNotif: {
    latest: [],
    unread: 0,
    data: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 1,
    },
  },
};
