import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const CandidateDetail = lazy(() => import('modules/admin/candidate/CandidateDetail'));

const CandidateDetailPage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <CandidateDetail />
    </Suspense>
  </AdminLayout>
);

export default CandidateDetailPage;
