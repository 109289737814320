import axios from 'axios';
import _get from 'lodash/get';

import { getUrlSegment } from 'utils/urlHelper';
import {
  getUserTokenStorage,
  clearUserTokenStorage,
  clearUserDataStorage,
  getAdminTokenStorage,
  clearAdminTokenStorage,
  clearAdminDataStorage,
  setLastRequestTime,
  getLastRequestTime,
  clearLastRequestTime,
} from 'utils/localStorage';

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const api = (customConfig, transformResp = true) => {
  const urlSegment = getUrlSegment(1);
  const token = urlSegment == 'admin' ? getAdminTokenStorage() : getUserTokenStorage();

  if (token) defaultConfig.headers.Authorization = `Bearer ${token}`;

  const client = axios.create({
    ...defaultConfig,
    ...(typeof customConfig === 'object' ? customConfig : {}),
  });

  client.interceptors.request.use(function (config) {
    const lastRequestTime = getLastRequestTime();
    if (
      config.headers.Authorization &&
      lastRequestTime &&
      Date.now() - lastRequestTime >= 30 * 60 * 1000 /* 30 minutes */
    ) {
      console.log('Idle limit reached: ', (Date.now() - lastRequestTime) / 1000, ' seconds');
      clearAdminDataStorage();
      clearAdminTokenStorage();
      clearUserDataStorage();
      clearUserTokenStorage();
      clearLastRequestTime();

      window.location.href = '/login';
    } else {
      setLastRequestTime(Date.now());
    }
    return config;
  });
  client.interceptors.response.use(
    (res) => (transformResp ? transformResponse(res) : res),
    async (error) => {
      if (axios.isCancel(error)) return transformError(error);

      const prevRequest = error.config;

      const status = _get(error, 'response.status') || 400;

      if (status === 403) {
        return transformError(error);
      }

      if (status === 401) {
        return transformError(error, true);
      }

      // do refresh token
      // if (status === 401 && !prevRequest._retry) {
      //     prevRequest['_retry'] = true;
      //     const refreshToken = getSession('rut');
      //     if (!refreshToken) return transformError(error, false);
      // }

      return transformError(error);
    }
  );

  return client;
};

const transformError = (error, clearAndRedirect) => {
  if (axios.isCancel(error)) {
    return {
      data: null,
      status: 499,
      statusText: 'Client Closed Request',
      headers: {},
      config: {},
      success: false,
      message: 'Client Closed Request',
    };
  }

  if (clearAndRedirect) {
    const urlSegment = getUrlSegment(1);

    if (urlSegment == 'admin') {
      clearAdminTokenStorage();
      clearAdminDataStorage();
    } else {
      clearUserTokenStorage();
      clearUserDataStorage();
    }

    setTimeout(() => {
      window.location.href = urlSegment == 'admin' ? '/admin' : '/login';
    }, 250);
  }

  return {
    data: _get(error, 'response.data') || null,
    status: _get(error, 'response.status') || 400,
    statusText: _get(error, 'response.statusText') || 'Bad request',
    headers: _get(error, 'response.headers') || {},
    config: _get(error, 'response.config') || error.config,
    success: false,
    message: _get(error, 'response.data.message', 'An error has occurred while processing your request.'),
  };
};

const transformResponse = (response) => {
  const data = _get(response, 'data.data', {});
  const status = _get(response, 'status', 400);
  const msg = axios.isCancel(response) ? 'message' : 'data.message';
  const message = _get(response, msg, '');
  return {
    data,
    message,
    success: status >= 200 && status < 300,
  };
};

export default api;
