import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { theme } from 'components/themes/v1';
import { readNotification } from 'services/notification';
import { TARGET_TYPE_APPLICATION } from 'constants/notification';
import { storeAdminLatestNotif, storeAdminUnreadNotif } from 'store/actions/notification';

import { UserNotification } from '.';

const Notification = styled.div`
  position: relative;
  width: 100%;
`;

const NotificationHeader = styled.div`
  font-size: 0.85rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.divider};
  padding: 10px 8px;
`;

const NotificationBody = styled.div`
  width: 100%;
  margin-bottom: 14px;
  height: 300px;
  overflow-y: auto;
`;

const NotificationFooter = styled.div`
  text-align: center;

  & a:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;

const NotificationItem = styled.a`
  display: block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.85rem;
  padding: 8px 12px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.divider};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray200};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
`;

const NotificationItemBadge = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: ${theme.colors.danger};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  right: 10px;
  top: 10px;
`;

const AdminNotification = (props) => {
  const notifData = useSelector((state) => state.adminNotif.latest);
  const notifUnread = useSelector((state) => state.adminNotif.unread);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClickNotif = async (notif, idx) => {
    const payload = { notifications: [notif.id] };
    const resp = await readNotification(payload);
    const isUnread = !notif.is_read;

    if (resp.success) {
      let newNotifData = notifData;
      newNotifData[idx].is_read = 1;
      dispatch(storeAdminLatestNotif(newNotifData));
      dispatch(storeAdminUnreadNotif(isUnread && notifUnread > 0 ? notifUnread - 1 : notifUnread));

      if (!notif.target_type) {
        return;
      }

      switch (notif.target_type) {
        case TARGET_TYPE_APPLICATION:
          navigate(`/admin/candidates/${notif.table_ref_id}`);
        default:
          return;
      }
    } else {
      const errMsg = _get(resp, 'data.messages.0') || t('http.request_failed');
      toast.error(errMsg);
    }
  };

  return (
    <Notification>
      <NotificationHeader>{t('menu.notifications')}</NotificationHeader>

      <NotificationBody>
        {notifData.map((notif, idx) => (
          <NotificationItem key={`notif${idx}`} onClick={() => onClickNotif(notif, idx)}>
            {notif.content}
            <NotificationItemBadge show={!notif.is_read} />
          </NotificationItem>
        ))}
      </NotificationBody>

      <NotificationFooter>
        <a href='#'>{t('see_all')}</a>
      </NotificationFooter>
    </Notification>
  );
};

export default AdminNotification;
