import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const AdminProfile = lazy(() => import('modules/admin/account/AdminProfile'));

const AdminProfilePage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <AdminProfile />
    </Suspense>
  </AdminLayout>
);

export default AdminProfilePage;
