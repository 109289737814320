import styled from '@emotion/styled';
import { Sidebar, AdminHeader } from '.';

const MainContainer = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin-left: 250px;
  margin-top: 70px;
`;

const AdminLayout = ({ children }) => (
  <MainContainer>
    <Sidebar />

    <AdminHeader />

    <ContentWrapper>{children}</ContentWrapper>
  </MainContainer>
);

export default AdminLayout;
