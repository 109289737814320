import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const AdminCandidatesArchive = lazy(() => import('modules/admin/candidate/AdminCandidatesArchive'));

const AdminCandidatesArchivePage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <AdminCandidatesArchive />
    </Suspense>
  </AdminLayout>
);

export default AdminCandidatesArchivePage;
