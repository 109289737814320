import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const UsersCandidate = lazy(() => import('modules/admin/user/UsersCandidate'));

const UsersCandidatePage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <UsersCandidate />
    </Suspense>
  </AdminLayout>
);

export default UsersCandidatePage;
