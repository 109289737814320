import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import { setLang, getUserTokenStorage } from 'utils/localStorage';
import { storeLang } from 'store/actions/lang';

const langOpt = {
  id: 'lang.indonesia',
  en: 'lang.english',
};

const Header = (props) => {
  const userToken = getUserTokenStorage();
  const lang = useSelector((state) => state.lang);
  const schoolData = useSelector((state) => state.school.detail);
  const name = _get(schoolData, 'name') || '';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onLangChange = (lng) => {
    dispatch(storeLang(lng));
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  return (
    <Navbar fixed='top' bg='light' expand='lg' className='navbar-aimsis'>
      <Container fluid>
        <Navbar.Brand className='cursor-pointer' onClick={() => navigate('/')}>
          {name}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto' />
          <Nav>
            <Nav.Link className='text-uppercase' onClick={() => navigate('/login')}>
              {t('menu.login')}
            </Nav.Link>
            <Nav.Link className='text-uppercase' onClick={() => navigate('/register')}>
              {t('menu.register')}
            </Nav.Link>
            <NavDropdown className='text-uppercase' title={t(langOpt[lang])} id='basic-nav-dropdown' alignright='true'>
              <NavDropdown.Item className='text-uppercase' onClick={() => onLangChange('id')}>
                {t('lang.indonesia')}
              </NavDropdown.Item>

              <NavDropdown.Item className='text-uppercase' onClick={() => onLangChange('en')}>
                {t('lang.english')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
