import { lazy, Suspense } from 'react';
import { Layout } from 'components/themes/v1';

const Profile = lazy(() => import('modules/account/Profile'));

const ProfilePage = (props) => (
  <Layout>
    <Suspense fallback={null}>
      <Profile />
    </Suspense>
  </Layout>
);

export default ProfilePage;
