import { reduceReducers } from 'store/reduxTools';
import initialState from 'store/initialState';

import lang from './lang';
import account from './account';
import school from './school';
import master from './master';
import user from './user';
import job from './job';
import application from './application';
import notification from './notification';

const reducers = reduceReducers(initialState, account, school, master, user, job, lang, application, notification);

export default reducers;
