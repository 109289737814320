/**
 * This file contains all api urls
 */
export const auth = {
  login: '/login',
  forgot_password: '/forgotpassword',
  reset_password: '/resetpassword',
  register: '/register',
  logout: '/logout',
  profile: '/profile',
  adminLogin: '/login/admin',
  profilePic: '/profile/picture',
  profileFile: '/profile/file/${type}',
  profileEmail: '/profile/email',
  profilePassword: '/profile/password',
  profileSurvey: '/profile/survey',
  profileSurveyId: '/profile/survey/${id}',
  isLockProfile: '/profile/islockprofile/${id}',
};

export const setting = {
  index: '/setting',
};

export const school = {
  index: '/school',
};

export const master = {
  jobStatus: '/master/job_status',
  jobStatusId: '/master/job_status/${id}',
  jobStatusSequenceUp: '/master/job_status/sequence_up/${id}',
  jobStatusSequenceDown: '/master/job_status/sequence_down/${id}',
  school: '/master/schools',
  schoolId: '/master/schools/${id}',
  schoolSequenceUp: '/master/schools/sequence_up/${id}',
  schoolSequenceDown: '/master/schools/sequence_down/${id}',
  location: '/master/location',
  locationId: '/master/location/${id}',
  skill: '/master/skills',
  skillId: '/master/skills/${id}',
  jobPosition: '/master/job_positions',
  jobPositionId: '/master/job_positions/${id}',
  religion: '/master/religions',
  blood_type: '/master/blood_type',
  marital_status: '/master/marital_status',
  gender: '/master/gender',
};

export const user = {
  admin: '/users/admin',
  adminId: '/users/admin/${id}',
  resetPassword: '/users/resetpassword',
  user: '/users',
  userId: '/users/${id}',
  cv: '/users/${id}/upload_cv',
};

export const job = {
  job: '/jobs',
  jobsAll: '/jobs/all',
  jobId: '/jobs/${id}',
  archive: '/jobs/${id}/archive',
  recommendation: '/jobs/recommendations',
  apply: '/jobs/${id}/apply',
  activate: '/jobs/${id}/activate',
};

export const application = {
  application: '/applications',
  applicationId: '/applications/${id}',
  applicationIdArchive: '/applications/${id}/archive',
  applied: '/applications/applied',
  export: '/applications/export',
  exportId: '/applications/${id}/export',
  question: '/applications/questions/recruitment',
  download: '/applications/${id}/download',
  answerId: '/applications/${id}/answers',
  interviewId: '/applications/${id}/interview_detail',
  inductionId: '/applications/${id}/induction_detail',
  interviewStatusId: '/applications/${id}/interview_status',
  inductionStatusId: '/applications/${id}/induction_status',
  offeredStatusId: '/applications/${id}/offered_status',
  offeredAmountId: '/applications/${id}/offered_amount',
};

export const notification = {
  notification: '/notifications',
  latest: '/notifications/latest',
  read: '/notifications/read',
};
