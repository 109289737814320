import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const AdminJobDetail = lazy(() => import('modules/admin/Job/AdminJobDetail'));

const AdminJobDetailPage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <AdminJobDetail />
    </Suspense>
  </AdminLayout>
);

export default AdminJobDetailPage;
