import _get from 'lodash/get';
import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import { APPLICATION_SET_DATA, APPLICATION_SET_DETAIL, USER_APPLICATION_SET_DATA } from 'store/types';

export default createReducer(initialState, {
  [APPLICATION_SET_DATA]: (state, action) => applicationDataUpdate(state, action.payload),
  [APPLICATION_SET_DETAIL]: (state, action) => applicationDetailUpdate(state, action.payload),
  [USER_APPLICATION_SET_DATA]: (state, action) => userApplicationDataUpdate(state, action.payload),
});

const applicationDataUpdate = (state, payload) => {
  return update(state, {
    application: {
      data: { $set: payload },
    },
  });
};

const applicationDetailUpdate = (state, payload) => {
  const user = _get(payload, 'user') || {};
  const application = _get(payload, 'application') || {};

  return update(state, {
    application: {
      detail: {
        user: { $set: user },
        application: { $set: application },
      },
    },
  });
};

const userApplicationDataUpdate = (state, payload) => {
  const { data, pagination } = payload;

  return update(state, {
    userApplication: {
      data: { $set: data },
      pagination: { $set: pagination },
    },
  });
};
