import _get from 'lodash/get';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getUserDataStorage, getLang, getAdminDataStorage } from 'utils/localStorage';
import { storeAccountProfile, storeAdminDetail } from 'store/actions/account';
// import { getAccountDetail } from 'services/account';
import { getSchoolData } from 'services/school';
import { storeSchoolDetail } from 'store/actions/school';
import { storeLang } from 'store/actions/lang';

const useInitFetch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // fetch user account
    (async () => {
      // set lang
      const currLang = getLang();
      const lang = currLang ? currLang : 'id';
      dispatch(storeLang(lang));

      // set admin data from local storage
      const admin = getAdminDataStorage();
      if (admin) dispatch(storeAdminDetail(admin));

      // set user from local storage
      const user = getUserDataStorage();
      if (user) dispatch(storeAccountProfile(user));

      // const token = getUserTokenStorage();
      // if (token) {
      //   const resp = await getAccountDetail();
      //   if (resp.success && token) {
      //     const user = _get(resp, 'data.user') || {};
      //     dispatch(storeAccount(user));
      //   }
      // }

      const school = await getSchoolData();
      if (school.success) {
        const schoolData = _get(school, 'data.school_data', {});
        dispatch(storeSchoolDetail(schoolData));
      }
    })();
  }, [dispatch]);
};

export default useInitFetch;
