import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import { SCHOOL_SET_DETAIL } from 'store/types';

export default createReducer(initialState, {
  [SCHOOL_SET_DETAIL]: (state, action) => schoolDetailUpdate(state, action.payload),
});

const schoolDetailUpdate = (state, payload) => {
  return update(state, {
    school: {
      detail: { $set: payload },
    },
  });
};
