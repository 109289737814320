import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import { USER_SET_ADMIN, USER_SET_CANDIDATE } from 'store/types';

export default createReducer(initialState, {
  [USER_SET_ADMIN]: (state, action) => userAdminUpdate(state, action.payload),
  [USER_SET_CANDIDATE]: (state, action) => userCandidateUpdate(state, action.payload),
});

const userAdminUpdate = (state, payload) => {
  return update(state, {
    user: {
      admin: { $set: payload },
    },
  });
};

const userCandidateUpdate = (state, payload) => {
  return update(state, {
    user: {
      candidate: { $set: payload },
    },
  });
};
