import _get from 'lodash/get';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Navbar, Nav, Modal, Button, Spinner, NavDropdown } from 'react-bootstrap';
import { PersonCheckFill, BellFill, Briefcase, BoxArrowRight, HouseDoorFill } from 'react-bootstrap-icons';

import { theme } from 'components/themes/v1';
import { userLogout } from 'services/account';
import { clearUserTokenStorage, clearUserDataStorage, clearLastRequestTime } from 'utils/localStorage';
import { getLatestNotif } from 'services/notification';
import { storeUserLatestNotif, storeUserUnreadNotif } from 'store/actions/notification';

import { UserNotification } from '.';

const NavbarIcon = styled.span`
  display: inline-block;
  position: relative;
  font-size: 1.25rem;
`;

const NavbarIconBadge = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  color: #fff;
  background-color: ${theme.colors.danger};
  font-size: 11px;
  min-width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50%;
  top: 2px;
  right: -5px;
  padding: 0 1px;
`;

const PrivateHeader = (props) => {
  const [modalLogout, setModalLogout] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const userData = useSelector((state) => state.account.detail);
  const notifUnread = useSelector((state) => state.userNotif.unread);
  const name = _get(userData, 'name') || '';

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogout = async () => {
    setLogoutLoading(true);
    const resp = await userLogout();
    setLogoutLoading(false);

    if (resp.success) {
      // clear token & data
      clearUserTokenStorage();
      clearUserDataStorage();
      clearLastRequestTime();

      toast.success(t('logout_success'));

      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    } else {
      toast.error(t('http.request_failed'));
    }
  };

  useEffect(() => {
    (async () => {
      const notif = await getLatestNotif();

      if (notif.success) {
        const notifData = _get(notif, 'data.notifications') || [];
        const notifUnread = _get(notif, 'data.unread') || 0;
        dispatch(storeUserLatestNotif(notifData));
        dispatch(storeUserUnreadNotif(notifUnread));
      }
    })();
  }, []);

  return (
    <Navbar fixed='top' bg='light' expand='lg' className='navbar-aimsis'>
      <Container fluid>
        <Nav>
          <Nav.Link className='p-0 mr-3' as={Link} to='/'>
            <NavbarIcon>
              <HouseDoorFill />
            </NavbarIcon>
          </Nav.Link>
        </Nav>
        <Navbar.Brand className='cursor-pointer p-0' as={Link} to='/profile'>
          <NavbarIcon>
            <PersonCheckFill />
          </NavbarIcon>{' '}
          {t('menu.welcome_name', { name })}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto' />
          <Nav>
            <Nav.Link as={Link} to='/applications' className='p-0'>
              <NavbarIcon>
                <Button variant='outline-primary'>Lihat Lamaran Saya</Button>
              </NavbarIcon>
            </Nav.Link>

            <NavDropdown
              title={
                <NavbarIcon>
                  <BellFill />
                  <NavbarIconBadge show={notifUnread && notifUnread > 0}>
                    {notifUnread >= 100 ? '99+' : notifUnread}
                  </NavbarIconBadge>
                </NavbarIcon>
              }
              id='basic-nav-dropdown'
              alignRight
            >
              <UserNotification />
            </NavDropdown>

            <Nav.Link className='p-0 mr-3' onClick={() => setModalLogout(true)}>
              <NavbarIcon>
                <BoxArrowRight />
              </NavbarIcon>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>

      <Modal show={modalLogout} onHide={() => setModalLogout(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center pb-4'>
          {t('are_you_sure_to_logout')}

          <div className='mt-3'>
            <Button variant='primary' onClick={() => onLogout()} className='mr-3'>
              {logoutLoading ? <Spinner animation='border' variant='light' size='sm' /> : null} {t('button.yes')}
            </Button>
            <Button variant='secondary' onClick={() => setModalLogout(false)}>
              {t('button.no')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};

export default PrivateHeader;
