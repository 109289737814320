export const ROLE_SUPERADMIN = 1;
export const ROLE_STAFF = 2;
export const ROLE_TEACHER = 3;
export const ROLE_STUDENT = 4;
export const ROLE_PARENT = 5;
export const ROLE_ALUMNI = 6;
export const ROLE_KARYAWAN = 7;
export const ROLE_EDIT_ABSENSI = 8;
export const ROLE_EKSTRAKURIKULER = 9;
export const ROLE_CETAK_RAPOR = 10;
export const ROLE_SURAT_MENYURAT = 11;
export const ROLE_STAFF_TU = 13;
export const ROLE_STAFF_TU_KESISWAAN = 14;
export const ROLE_MONITOR_NILAI = 15;
export const ROLE_GURU_PIKET = 16;
export const ROLE_HEAD_TEACHER = 17;
export const ROLE_HEADMASTER = 18;
export const ROLE_CURRICULUM = 19;
export const ROLE_VICE_PRINCIPAL = 20;
export const ROLE_TEACHER_ASSISTANT = 21;
export const ROLE_LIBRARIAN = 22;
export const ROLE_SECURITY = 23;
export const ROLE_WORKER = 24;

export const ROLE_ADMIN_LIKE_VIEW_ONLY = 999;
export const ROLE_EDUCATION_MANAGER = 1000;
export const ROLE_BIDANG_LITBANG = 1001;
export const ROLE_REGIONAL_HEAD = 1002;
export const ROLE_MANAGER_SDM_STAFF_SDM_PERSONALIA = 1003;
export const ROLE_MANAGER_STAFF_KEUANGAN = 1004;

export const ROLE_STAFF_PERSONALIA = 2000;
export const ROLE_CANDIDATE = 2001;

export const PERSONALIA_ROLES = [ROLE_SUPERADMIN, ROLE_MANAGER_SDM_STAFF_SDM_PERSONALIA, ROLE_STAFF_PERSONALIA];

export const EMPLOYEE_ROLES = [
  ROLE_SUPERADMIN,
  ROLE_STAFF,
  ROLE_TEACHER,
  ROLE_KARYAWAN,
  ROLE_EDIT_ABSENSI,
  ROLE_EKSTRAKURIKULER,
  ROLE_CETAK_RAPOR,
  ROLE_SURAT_MENYURAT,
  ROLE_STAFF_TU,
  ROLE_STAFF_TU_KESISWAAN,
  ROLE_MONITOR_NILAI,
  ROLE_GURU_PIKET,
  ROLE_HEAD_TEACHER,
  ROLE_HEADMASTER,
  ROLE_CURRICULUM,
  ROLE_VICE_PRINCIPAL,
  ROLE_TEACHER_ASSISTANT,
  ROLE_LIBRARIAN,
  ROLE_SECURITY,
  ROLE_WORKER,
  ROLE_ADMIN_LIKE_VIEW_ONLY,
  ROLE_EDUCATION_MANAGER,
  ROLE_BIDANG_LITBANG,
  ROLE_REGIONAL_HEAD,
  ROLE_MANAGER_SDM_STAFF_SDM_PERSONALIA,
  ROLE_MANAGER_STAFF_KEUANGAN,
  ROLE_STAFF_PERSONALIA,
  ROLE_CANDIDATE,
];

export const USER_ROLES = {
  [ROLE_SUPERADMIN]: 'role.superadmin',
  [ROLE_STAFF_PERSONALIA]: 'role.admin',
  [ROLE_CANDIDATE]: 'role.candidate',
};

export const getRoleName = (roleId) => {
  if (USER_ROLES[roleId]) {
    return USER_ROLES[roleId];
  }

  return '';
};
