import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const Candidates = lazy(() => import('modules/admin/candidate/Candidates'));

const CandidatesPage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <Candidates />
    </Suspense>
  </AdminLayout>
);

export default CandidatesPage;
