import { lazy, Suspense } from 'react';
import { Layout } from 'components/themes/v1';

const ForgotPassword = lazy(() => import('modules/account/ForgotPassword'));

const ForgotPasswordPage = (props) => (
  <Layout>
    <Suspense fallback={null}>
      <ForgotPassword />
    </Suspense>
  </Layout>
);

export default ForgotPasswordPage;
