/*
    CREATE TYPES GUIDE
    -------------------

    <STORE_TYPE>_<UPDATE_TYPE>_<NAME_OR_STATE>

    STORE_TYPE      : ACCOUNT, CATEGORY, STATISTIC
    UPDATE_TYPE     : ADD, SET, UPDATE, EDIT, DELETE, CLEAR
    NAME_OR_STATE   : PROFILE, COMPANY_PROFILE, CHAT_ROOM

    Capital is must
*/

// lang
export const SET_LANG = 'SET_LANG';

// account
export const ACCOUNT_SET_PROFILE = 'ACCOUNT_SET_PROFILE';
export const ACCOUNT_SET_ADMIN_DETAIL = 'ACCOUNT_SET_ADMIN_DETAIL';

// school
export const SCHOOL_SET_DETAIL = 'SCHOOL_SET_DETAIL';

// master
export const MASTER_SET_JOB_POSITION = 'MASTER_SET_JOB_POSITION';
export const MASTER_SET_JOB_STATUS = 'MASTER_SET_JOB_STATUS';
export const MASTER_SET_SCHOOL = 'MASTER_SET_SCHOOL';
export const MASTER_SET_SCHOOL_LOCATION = 'MASTER_SET_SCHOOL_LOCATION';
export const MASTER_SET_SKILL = 'MASTER_SET_SKILL';

// user
export const USER_SET_ADMIN = 'USER_SET_ADMIN';
export const USER_SET_CANDIDATE = 'USER_SET_CANDIDATE';

// job
export const JOB_SET_DATA = 'JOB_SET_DATA';
export const JOB_SET_DETAIL = 'JOB_SET_DETAIL';
export const JOB_SET_RECOMMENDATION = 'JOB_SET_RECOMMENDATION';

// application
export const APPLICATION_SET_DATA = 'APPLICATION_SET_DATA';
export const APPLICATION_SET_DETAIL = 'APPLICATION_SET_DETAIL';
export const USER_APPLICATION_SET_DATA = 'USER_APPLICATION_SET_DATA';

// notification
export const NOTIF_SET_ADMIN_LATEST = 'NOTIF_SET_ADMIN_LATEST';
export const NOTIF_SET_ADMIN_DATA = 'NOTIF_SET_ADMIN_DATA';
export const NOTIF_SET_ADMIN_UNREAD = 'NOTIF_SET_ADMIN_UNREAD';
export const NOTIF_SET_USER_LATEST = 'NOTIF_SET_USER_LATEST';
export const NOTIF_SET_USER_DATA = 'NOTIF_SET_USER_DATA';
export const NOTIF_SET_USER_UNREAD = 'NOTIF_SET_USER_UNREAD';
