import styled from '@emotion/styled';
import { Header, PrivateHeader } from '.';

import { getUserTokenStorage } from 'utils/localStorage';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #edecec;
  overflow-y: auto;
`;

/** Default layout */
/**
 * in real app
 * please replace isLoggedIn from props to redux
 */
const Layout = ({ children }) => {
  const userToken = getUserTokenStorage();

  return (
    <Wrapper>
      {userToken ? <PrivateHeader /> : <Header />}

      {children}
    </Wrapper>
  );
};

export default Layout;
