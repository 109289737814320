import { ACCOUNT_SET_PROFILE, ACCOUNT_SET_ADMIN_DETAIL } from 'store/types';

export const storeAccountProfile = (data) => {
  return {
    type: ACCOUNT_SET_PROFILE,
    payload: data,
  };
};

export const storeAdminDetail = (data) => {
  return {
    type: ACCOUNT_SET_ADMIN_DETAIL,
    payload: data,
  };
};
