import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import { ACCOUNT_SET_PROFILE, ACCOUNT_SET_ADMIN_DETAIL } from 'store/types';

export default createReducer(initialState, {
  [ACCOUNT_SET_PROFILE]: (state, action) => accountProfileUpdate(state, action.payload),
  [ACCOUNT_SET_ADMIN_DETAIL]: (state, action) => accountAdminDetailUpdate(state, action.payload),
});

const accountProfileUpdate = (state, payload) => {
  return update(state, {
    account: {
      detail: { $set: payload },
    },
  });
};

const accountAdminDetailUpdate = (state, payload) => {
  return update(state, {
    account: {
      adminDetail: { $set: payload },
    },
  });
};
