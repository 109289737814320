import {
  NOTIF_SET_ADMIN_LATEST,
  NOTIF_SET_ADMIN_DATA,
  NOTIF_SET_ADMIN_UNREAD,
  NOTIF_SET_USER_LATEST,
  NOTIF_SET_USER_DATA,
  NOTIF_SET_USER_UNREAD,
} from 'store/types';

export const storeAdminLatestNotif = (data) => {
  return {
    type: NOTIF_SET_ADMIN_LATEST,
    payload: data,
  };
};

export const storeAdminDataNotif = (data) => {
  return {
    type: NOTIF_SET_ADMIN_DATA,
    payload: data,
  };
};

export const storeAdminUnreadNotif = (data) => {
  return {
    type: NOTIF_SET_ADMIN_UNREAD,
    payload: data,
  };
};

export const storeUserLatestNotif = (data) => {
  return {
    type: NOTIF_SET_USER_LATEST,
    payload: data,
  };
};

export const storeUserDataNotif = (data) => {
  return {
    type: NOTIF_SET_USER_DATA,
    payload: data,
  };
};

export const storeUserUnreadNotif = (data) => {
  return {
    type: NOTIF_SET_USER_UNREAD,
    payload: data,
  };
};
