import _get from 'lodash/get';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { PencilSquare } from 'react-bootstrap-icons';

import theme from 'components/themes/v1/theme';
import UserDummy from 'assets/images/user-dummy.jpg';

const SidebarWrapper = styled.div`
  width: 245px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1040;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  & .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 12px;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 36px 12px;
`;

const SidebarLogo = styled.img`
  width: 42px;
  margin-right: 8px;
`;

const SidebarBrand = styled.div`
  margin: auto 0;
  font-weight: bold;
`;

const SidebarMenu = styled.div`
  width: 100%;
`;

const SidebarUserWrapper = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-top: 50px;
  margin-bottom: 32px;
`;

const SidebarUser = styled.div`
  position: relative;
  background-color: #ff0a00;
  padding: 24px 14px;
  text-align: center;
`;

const UserProfileAction = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  cursor: pointer;
  padding: 8px 14px;
  color: ${theme.colors.primary};
`;

const UserImage = styled.img`
  width: 70px;
  height: 70px;
  margin: auto 12px auto 0;
  border-radius: ${(props) => (props.circle ? '50%' : 0)};
`;

const Sidebar = (props) => {
  const adminData = useSelector((state) => state.account.adminDetail);
  const schoolData = useSelector((state) => state.school.detail);

  const schoolLogo = _get(schoolData, 'logo') || '';
  const schoolName = _get(schoolData, 'name') || '';
  const adminName = _get(adminData, 'name') || '';
  const adminProfilePic = _get(adminData, 'profile.profile_picture') || '';

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SidebarWrapper className='shadow-3'>
      <SidebarHeader>
        <SidebarLogo src={schoolLogo} alt='-' />
        <SidebarBrand>{schoolName}</SidebarBrand>
      </SidebarHeader>

      <SidebarMenu>
        <ProSidebar>
          <Menu iconShape='square'>
            <MenuItem>
              {t('menu.candidate_management')}
              <Link to='/admin/candidates' />
            </MenuItem>

            <MenuItem>
              {t('menu.jobs')}
              <Link to='/admin/jobs' />
            </MenuItem>

            <MenuItem>
              {t('menu.users')}
              <Link to='/admin/users/candidate' />
            </MenuItem>

            {/* <SubMenu title={t('menu.users')}>
              <MenuItem>
                {t('menu.admin')}
                <Link to='/admin/users/admin' />
              </MenuItem>
              <MenuItem>
                {t('menu.user')}
                <Link to='/admin/users/candidate' />
              </MenuItem>
            </SubMenu> */}

            <MenuItem>
              {t('menu.settings')}
              <Link to='/admin/setting/school' />
            </MenuItem>

            <SubMenu title={t('menu.master_data')}>
              <MenuItem>
                {t('menu.job_kind')}
                <Link to='/admin/master/jobposition' />
              </MenuItem>
              <MenuItem>
                {t('menu.job_status')}
                <Link to='/admin/master/jobstatus' />
              </MenuItem>
              {/* <MenuItem>
                {t('menu.skill')}
                <Link to='/admin/master/skill' />
              </MenuItem>
              <MenuItem>
                {t('menu.school_name')}
                <Link to='/admin/master/school' />
              </MenuItem>
              <MenuItem>
                {t('menu.school_location')}
                <Link to='/admin/master/location' />
              </MenuItem> */}
            </SubMenu>

            <SubMenu title={t('menu.archive')}>
              <MenuItem>
                {t('menu.job_archive')}
                <Link to='/admin/archive/jobs' />
              </MenuItem>
              <MenuItem>
                {t('menu.candidate_archive')}
                <Link to='/admin/archive/candidates' />
              </MenuItem>
            </SubMenu>
          </Menu>
        </ProSidebar>
      </SidebarMenu>

      <SidebarUserWrapper>
        <SidebarUser>
          <UserProfileAction onClick={() => navigate('/admin/profile')}>
            <PencilSquare />
          </UserProfileAction>

          <UserImage circle src={adminProfilePic ? adminProfilePic : UserDummy} alt='-' />
          <h5 className='mt-3'>{t('menu.hello_name', { name: adminName })}</h5>
          <div>{t('menu.staff_personalia')}</div>
        </SidebarUser>
      </SidebarUserWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
