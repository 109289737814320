import update from 'immutability-helper';

import initialState from 'store/initialState';
import { createReducer } from 'store/reduxTools';
import { JOB_SET_DATA, JOB_SET_DETAIL, JOB_SET_RECOMMENDATION } from 'store/types';

export default createReducer(initialState, {
  [JOB_SET_DATA]: (state, action) => jobDataUpdate(state, action.payload),
  [JOB_SET_DETAIL]: (state, action) => jobDetailUpdate(state, action.payload),
  [JOB_SET_RECOMMENDATION]: (state, action) => jobRecommendationUpdate(state, action.payload),
});

const jobDataUpdate = (state, payload) => {
  const { data, pagination } = payload;

  return update(state, {
    job: {
      data: { $set: data },
      pagination: { $set: pagination },
    },
  });
};

const jobDetailUpdate = (state, payload) => {
  return update(state, {
    job: {
      detail: { $set: payload },
    },
  });
};

const jobRecommendationUpdate = (state, payload) => {
  return update(state, {
    job: {
      recommendation: { $set: payload },
    },
  });
};
