import { lazy, Suspense } from 'react';
import { AdminLayout } from 'components/themes/admin';

const AddJob = lazy(() => import('modules/admin/Job/AddJob'));

const AddJobPage = (props) => (
  <AdminLayout>
    <Suspense fallback={null}>
      <AddJob />
    </Suspense>
  </AdminLayout>
);

export default AddJobPage;
